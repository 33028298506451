var render = function render(_c,_vm){return _c('div',{staticClass:"zt-block"},[(_vm.props.type === 1)?_c('div',{staticClass:"item"},[_c('h3',[_vm._v("客户需求信息")]),_c('div',{staticStyle:{"width":"500px","cursor":"pointer"}},[_c('div',{staticClass:"flex"},[_c('div',{staticStyle:{"width":"106px","height":"106px","margin-right":"10px"}},[(_vm.props.detailInfo.comp && _vm.props.detailInfo.comp.logo)?_c('div',{staticClass:"bg_img",style:({
              backgroundImage:
                'url(' +
                _vm.props.imgUrl +
                _vm.props.detailInfo.comp.logo +
                '!width_250px' +
                ')',
            })}):_c('div',{staticClass:"bg_img",style:({
              backgroundImage:
                'url(' +
                _vm.props.staticUrl +
                'design/placeholder/zhanweilogo%402x.jpg!width_250px' +
                ')',
            })})]),(_vm.props.detailInfo.comp && _vm.props.detailInfo.comp.name)?_c('div',{staticClass:"flex-1 flex-justify-between",staticStyle:{"text-align":"left","position":"relative"}},[_c('h3',{staticClass:"font14-grey ellipsis1"},[_vm._v(" "+_vm._s(_vm.props.detailInfo.comp.name)+" ")]),_vm._t("default")],2):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"item"},[_c('el-row',[_c('el-col',{attrs:{"span":20}},[_c('h3',{staticClass:"font14-grey ellipsis"},[_vm._v(_vm._s(_vm.props.detailInfo.title))]),(
            _vm.props.detailInfo[_vm.props.detailInfo.type] &&
            _vm.props.detailInfo[_vm.props.detailInfo.type].cat
          )?_c('p',{staticStyle:{"color":"#b3b3b3"}},[_vm._v(" "+_vm._s(_vm.props.detailInfo[_vm.props.detailInfo.type].cat.name)+" ")]):_vm._e()]),_c('el-col',{attrs:{"span":4}},[([2, 3].includes(_vm.props.detailInfo.state) && _vm.props.type === 2)?_c('div',[_c('img',{attrs:{"src":require("@/assets/images/desgin/product_stat1.png"),"alt":"加载中..."}})]):_vm._e()])],1),(
        _vm.props.detailInfo[_vm.props.detailInfo.type] &&
        _vm.props.detailInfo[_vm.props.detailInfo.type].productImgs
      )?_c('div',{staticClass:"img_group"},_vm._l((_vm.props.detailInfo[_vm.props.detailInfo.type]
          .productImgs),function(item,index){return _c('div',{key:index,staticClass:"bg_img",style:({
          backgroundImage:
            'url(' + _vm.props.imgUrl + item + '!width_250px' + ')',
        })})}),0):_vm._e()],1),_c('div',{staticClass:"list-item flex"},[_c('div',{staticClass:"list-item-tr"},[_c('div',{staticClass:"font14-grey1"},[_vm._v("需求类型")]),(_vm.props.detailInfo.type == 'peitao')?_c('div',[_vm._v("加工需求")]):_vm._e(),(_vm.props.detailInfo.type == 'gaikuang')?_c('div',[_vm._v("设计需求")]):_vm._e(),(_vm.props.detailInfo.type == 'dayang')?_c('div',[_vm._v("打样")]):_vm._e()]),(_vm.props.detailInfo.type === 'gaikuang')?_c('div',{staticClass:"list-item-tr"},[_c('div',{staticClass:"font14-grey1"},[_vm._v("设计类型")]),(
          _vm.props.detailInfo[_vm.props.detailInfo.type] &&
          _vm.props.detailInfo[_vm.props.detailInfo.type].fileTypes.length > 0
        )?_c('div',_vm._l((_vm.props.detailInfo[_vm.props.detailInfo.type]
            .fileTypes),function(item1,idx1){return _c('div',{key:idx1},[(item1 == '3d')?_c('div',[_vm._v("3d渲染图")]):_vm._e(),(item1 == 'material')?_c('div',[_vm._v("电商素材")]):_vm._e(),(item1 == 'size')?_c('div',[_vm._v("尺寸图")]):_vm._e()])}),0):_vm._e()]):_vm._e(),_c('div',{staticClass:"list-item-tr",staticStyle:{"min-width":"300px","max-width":"350px"}},[_c('div',{staticClass:"font14-grey1"},[_vm._v("出价预算")]),(
          _vm.props.detailInfo[_vm.props.detailInfo.type] &&
          _vm.props.detailInfo[_vm.props.detailInfo.type].prices &&
          _vm.props.detailInfo[_vm.props.detailInfo.type].prices.length > 0
        )?_c('div',[(_vm.props.detailInfo[_vm.props.detailInfo.type].prices[0])?_c('span',[_vm._v("￥"+_vm._s(_vm._f("priceFilt")(_vm.props.detailInfo[_vm.props.detailInfo.type].prices[0])))]):_vm._e(),(_vm.props.detailInfo[_vm.props.detailInfo.type].prices[1])?_c('span',[_vm._v("~"+_vm._s(_vm._f("priceFilt")(_vm.props.detailInfo[_vm.props.detailInfo.type].prices[1])))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"list-item-tr"},[_c('div',{staticClass:"font14-grey1"},[_vm._v("期望交货时间")]),(
          _vm.props.detailInfo[_vm.props.detailInfo.type] &&
          _vm.props.detailInfo[_vm.props.detailInfo.type].giveDate
        )?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.props.detailInfo[_vm.props.detailInfo.type].giveDate,"YYYY-MM-DD"))+" ")]):_vm._e()])]),_c('div',{staticClass:"list-desc"},[_c('div',{staticClass:"flex"},[_c('div',[_vm._v("需求说明")]),(_vm.props.detailInfo[_vm.props.detailInfo.type])?_c('div',{staticClass:"font14-grey flex-1"},[_vm._v(" "+_vm._s(_vm.props.detailInfo[_vm.props.detailInfo.type].desc)+" ")]):_vm._e()]),_c('div',{staticClass:"flex"},[_c('div',[_vm._v("参考链接")]),(
          _vm.props.detailInfo[_vm.props.detailInfo.type] &&
          _vm.props.detailInfo[_vm.props.detailInfo.type].urls.length > 0
        )?_c('div',{staticClass:"flex-1"},_vm._l((_vm.props.detailInfo[_vm.props.detailInfo.type]
            .urls),function(item,index){return _c('div',{key:index,staticClass:"font14-grey"},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- 应答详情 -->
  <div v-if="item && item.id" class="pipe" style="height: 100%">
    <div>
      <DayangDetail
        v-if="item.type == 'dayang'"
        :item="item"
        @onSuccess="refreshItems"
      ></DayangDetail>
      <DemandDetail
        :detailInfo="item"
        :imgUrl="imgUrl"
        :staticUrl="staticUrl"
        :type="1"
        v-else
      >
        <el-button
          @click.stop="viewIntro(item.comp.id)"
          style="color: #5074ee; position: absolute; bottom: 0; left: 0"
          type="text"
          >查看公司详情资质 ></el-button
        >
      </DemandDetail>
      <div class="zt-block">
        <el-row type="flex" :gutter="24" class="interaction">
          <el-col :span="12">
            <div class="interaction-item">
              <h3>
                <el-row type="flex" justify="space-between">
                  <el-col>应答信息</el-col>
                  <el-col
                    v-if="item.offers && item.offers.length > 0"
                    style="text-align: right"
                  >
                    <el-button
                      type="text"
                      :disabled="!!item.offers[0].orderId"
                      @click="popPipe(2)"
                    >
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </el-col>
                </el-row>
              </h3>
              <DoAccept
                v-if="item.offers && item.offers.length > 0"
                :price="item.offers[0][item.type].price"
                :giveDate="
                  item.type == 'dayang'
                    ? item.offers[0][item.type].checkDate
                    : item.offers[0][item.type].giveDate
                "
              ></DoAccept>
              <UnAccept v-else>
                <el-button @click="popPipe(1)" size="mini" type="primary" round
                  >我要应答</el-button
                >
              </UnAccept>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="interaction-item">
              <h3>合同信息</h3>
              <!-- 
                合同显示逻辑
                1.未应答,提示未应答
                2.已应答未生成订单,显示上传合同
                3.已应答并生成订单,显示合同并不可修改
              -->
              <div v-if="item.offers && item.offers.length > 0">
                <div v-if="!item.offers[0].orderId">
                  <el-radio-group v-model="ruleForm.custom_cover_page">
                    <el-radio :label="0">自动填充页头页尾</el-radio>
                    <el-radio :label="1">自定义页头页尾</el-radio>
                  </el-radio-group>
                  <div class="contract-upload">
                    <UploadPdf
                      v-if="showImgs"
                      v-model="ruleForm.contract_file"
                      :prefix="contractUrl"
                      ::contract_type="contractType"
                      :type="ruleForm.custom_cover_page"
                    ></UploadPdf>
                  </div>
                </div>
                <div v-else>
                  <el-button type="text" size="mini">
                    <i class="iconfont icon-PDF1"></i>
                    {{ item.type | contractFilt }}合同
                  </el-button>
                </div>
              </div>
              <UnAccept v-else></UnAccept>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- <DayangInfo v-if="item.type == 'dayang'" :item="item" @onSuccess="refreshItems"></DayangInfo> -->
    <div class="zt-block" style="min-width: 950px; min-height: 400px">
      <div class="reply-header">我要回复</div>
      <!-- <div class="reply-header flex">
                <div class="flex-1">
                    <span style="margin-right:15px;">回复内容</span>
                    <span v-if="reply_list" class="font14-grey1">共{{reply_list.length}}条</span>
                </div>
                <el-button v-if="item.state>=3" @click="popReply()" size="small" type="primary" plain>我要回复</el-button>
      </div>-->
      <div class="reply-content">
        <div
          v-if="item.offers && item.offers.length > 0"
          class="reply-input flex"
        >
          <div>
            <i class="iconfont icon-touxiang"></i>
          </div>
          <div class="flex-1">
            <div>
              <el-input
                style="width: 100%; height: 100%"
                type="textarea"
                :rows="2"
                placeholder="请输入回复内容"
                v-model="replyText"
                maxlength="150"
                show-word-limit
              ></el-input>
            </div>
            <div style="text-align: right; padding-top: 5px">
              <el-button @click="replySubmit()" size="small" type="primary"
                >回复</el-button
              >
            </div>
          </div>
        </div>
        <div
          v-if="item.offers && item.offers.length > 0"
          class="reply-header1 flex"
        >
          <div>历史回复</div>
          <span>共{{ (reply_list && reply_list.length) || 0 }}条</span>
        </div>
        <div v-if="reply_list && reply_list.length > 0" class="reply">
          <div
            v-for="(item1, idx1) in reply_list"
            :key="idx1"
            class="reply-item"
          >
            <div class="reply-name flex">
              <!-- <div class="bg_img"
              :style="{backgroundImage: 'url(' + imgUrl+item1.fromComp.logo+'!width_250px' + ')'}"></div>-->
              <div v-if="item1.fromCompId == comp_id">我的回复</div>
              <div v-else>{{ item1.fromComp.name }}</div>
            </div>
            <div class="reply-padding">
              <div class="reply-text">{{ item1.content }}</div>
              <div class="reply-time flex">
                <div class="flex-1">
                  {{ item1.createdAt | moment("YYYY年MM月DD日") }}
                </div>
                <!-- <el-button @click="popReply(item1,idx)" type="text">回复</el-button> -->
                <!-- <el-button v-if="item1.fromCompId == comp_id" @click="editReply(item1,idx)" size="small" type="text">修改</el-button> -->
                <el-button
                  v-if="item1.fromCompId == comp_id"
                  @click="del_reply(item1)"
                  style="color: #f66f6a"
                  size="small"
                  type="text"
                  >撤回</el-button
                >
              </div>
            </div>
            <!-- <div v-if="replyIndex === idx" class="reply-input">
                            <div>
                                <el-input style="width:100%;height:100%" type="textarea" :rows="3" placeholder="请输入回复内容"
                                v-model="replyText"></el-input>
                            </div>
                            <div style="text-align: right;padding-top:5px;">
                                <span style="line-height: 32px;margin-right: 10px;" class="font14-grey">{{replyText.length}}</span>
                                <el-button v-if="replyType == 2" @click="replySubmit()" size="small" type="primary">保存</el-button>
                                <el-button v-else @click="replySubmit()" size="small" type="primary">发送</el-button>
                            </div>
            </div>-->
          </div>
        </div>
        <div
          style="padding: 30px; text-align: center"
          v-if="reply_list && reply_list.length == 0"
        >
          <div v-if="item.offers && item.offers.length > 0">暂无回复内容</div>
          <div v-if="item.offers && item.offers.length == 0">
            请先
            <el-button @click="popPipe(1)" type="text">我要应答</el-button
            >才能回复内容哦！
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="zt-block" style="padding: 20px">
      <el-button
        @click="back()"
        style="margin-right: 50px"
        size="small"
        type="primary"
        >返 回</el-button
      >
    </div> -->
    <el-dialog
      width="550px"
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
    >
      <AcceptForm
        :updateMsg="updateMsg"
        @onSuccess="refreshItems"
        @hide="popVisible = false"
      ></AcceptForm>
    </el-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import localForage from "localforage";
import { IMG_URL_PRE, STATIC_URL_PRE } from "@/config";
import {
  getRequireId,
  replyList,
  addReply,
  editReply,
  delReply,
  setContract,
} from "@/service/want";
import { contractView } from "@/service/contract";
import UnAccept from "../components/UnAccept.vue";
import DoAccept from "../components/DoAccept.vue";
import AcceptForm from "../components/AcceptForm.vue";
import UploadPdf from "@/components/upload/UploadPdf.vue";
import ItemPipe from "./ItemPipe.vue";
// import ViewPipe from './ViewPipe.vue';
// import DayangInfo from "./dayang/DayangInfo.vue";
import DemandDetail from "../wantFollow/DemandDetail.vue";
import DayangDetail from "../wantFollow/DayangDetail.vue";
export default {
  components: {
    // ItemPipe,
    // ViewPipe,
    // DayangInfo,
    UnAccept,
    DoAccept,
    AcceptForm,
    UploadPdf,
    DayangDetail,
    DemandDetail,
  },
  watch: {
    "ruleForm.contract_file": {
      handler(val) {
        if (val) {
          if (!this.item.offers[0].orderId) {
            this.setContractInfo(val);
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    contractUrl() {
      return `ht/${this.item.type}`;
    },
    contractType() {
      const obj = [
        { key: "dayang", value: 3 },
        { key: "gaikuang", value: 2 },
      ].find((item) => {
        return item.key === this.item.type;
      });
      return obj && obj.value;
    },
  },
  data() {
    return {
      acceptFormOffers: {},
      updateMsg: {},
      ruleForm: {
        contract_file: "",
        custom_cover_page: 0, // 0自动填充页头页尾, 1自定义页头页尾
      },
      showImgs: true,
      imgUrl: IMG_URL_PRE,
      staticUrl: STATIC_URL_PRE,
      popTitle: "",
      popVisible: false,
      queryOpt: {
        where: {
          key: null,
          catIds: null,
        },
        page: {
          pageNumber: 1,
          pageCount: 999,
        },
      },
      total: 0,
      item: {},
      flagDesc: false,
      reply_list: null,
      replyFlag: false,
      replyItem: "",
      replyText: "",
      replyIndex: null,
      replyType: null,
      comp_id: "",
      hackReset: true, //刷新子组件
      // offers:{},
    };
  },
  created() {
    this.comp_id = this.$store.state.loginUser.comp.id;
    this.refreshItems();
  },
  mounted() {},
  methods: {
    viewIntro(id) {
      window.open("/zizhi/view/" + id);
    },
    setContractInfo(val) {
      const obj = {
        offerId: this.item.offers[0].id,
        contract: {
          file: `${val}`,
          customCoverPage: 0,
        },
      };
      setContract(obj).then(() => {
        this.refreshItems();
      });
    },
    goto(link) {
      this.$router.push(link);
    },
    viewProduct() {
      let comp_type = this.$store.state.loginUser.comp.comp_type;
      switch (comp_type) {
        case 1:
          window.open(
            "/factory/zysp/detail/" +
              this.item.offers[0][this.item.type].item.id
          );
          break;
        case 2:
          window.open(
            "/desgin/fchz/detail/" + this.item.offers[0][this.item.type].item.id
          );
          break;
      }
    },
    back() {
      this.$router.push({ path: "want/joinWant", query: { type: 1 } });
    },
    refreshItems() {
      let obj = { offers: { compIds: [this.comp_id] } };
      getRequireId(this.$route.params.id, obj)
        .then((rst) => {
          this.item = rst;
          if (rst.offers && rst.offers.length > 0) {
            this.offers = rst.offers[0][rst.type];
            if (rst.offers[0].contract) {
              this.ruleForm.custom_cover_page =
                rst.offers[0].contract.customCoverPage;
              this.ruleForm.contract_file = rst.offers[0].contract.file;
            }

            this.replylist();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.hackReset = false;
      this.$nextTick(() => {
        this.hackReset = true;
      });
    },
    showDesc() {
      this.flagDesc = !this.flagDesc;
    },
    replylist() {
      console.log("replyList");
      replyList(this.offers.id)
        .then((rst) => {
          this.total = rst.count;
          this.reply_list = rst.rows;
          // console.log(this.reply_list);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    popPipe(type) {
      if (type === 1) {
        this.popTitle = "填写应答信息";
        this.updateMsg = {};
      } else if (type === 2) {
        this.popTitle = "修改应答信息";
        this.updateMsg = this.item.offers[0][this.item.type];
      }
      this.popVisible = true;
    },
    viewPipe() {
      this.popVisible = true;
    },
    replySubmit() {
      if (this.replyText.trim()) {
        let item = {
          offerId: this.offers.id,
          content: this.replyText,
        };
        if (this.replyType == 2) {
          editReply(this.replyItem.id, { content: this.replyText })
            .then((rst) => {
              this.replyFlag = false;
              this.replyItem = "";
              this.$message.success("保存成功");
              this.replylist();
              console.log(rst);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          addReply(item)
            .then((rst) => {
              this.replyFlag = false;
              this.replyText = "";
              this.$message.success("添加成功");
              this.replylist();
              console.log(rst);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        this.$message.error("请输入内容");
      }
    },
    del_reply(item) {
      this.$confirm("确认是否撤回?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delReply(item.id)
          .then((rst) => {
            this.replyFlag = false;
            this.$message.success("撤回成功");
            this.replylist();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.zt-block {
  box-shadow: none;
}
.item {
  padding: 20px 30px;
  font-size: 14px;
  border: 1px solid rgba(230, 230, 230, 1);
}
.item h3 {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
}
.img_group > div {
  width: 120px;
  height: 120px;
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  position: relative;
  left: -5px;
}
.list-item {
  padding: 10px 0;
  background: rgba(250, 250, 255, 1);
}
.list-item > div {
  min-width: 160px;
  min-height: 110px;
  padding: 0 30px;
  border-right: 1px solid #e6e6e6;
  position: relative;
}
.list-item > div:last-child {
  border-right: 0;
}
.list-item .list-item-tr > div:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
  margin-top: 10px;
  word-wrap: break-word;
}
.btn-flagDesc {
  position: absolute;
  right: 20px;
  bottom: 10px;
}
.flagDesc {
  display: inline-block;
  font-size: 12px;
  position: relative;
  top: 4px;
}
.flagDesc.active {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
  -o-transform: rotate(180deg); /* Opera */
}
.list-desc {
  padding: 30px;
  border-top: 1px solid rgba(230, 230, 230, 1);
  border-bottom: 1px solid rgba(230, 230, 230, 1);
}
.list-desc > div {
  margin-bottom: 20px;
}
.list-desc > div:last-child {
  margin-bottom: 0px;
}
.list-desc > div > div:first-child {
  font-size: 14px;
  color: #808080;
  line-height: 22px;
  margin-bottom: 12px;
  margin-right: 10px;
}
.list-answer {
  padding: 20px 30px;
  font-size: 14px;
  color: #ccc;
}
.list-answer > div {
  margin-bottom: 10px;
}
.list-answer > div:last-child {
  margin-bottom: 0;
}
.list-answer span:nth-child(1) {
  display: inline-block;
  vertical-align: top;
  width: 56px;
  text-align: right;
  margin-right: 20px;
}
/* -----回复------- */
.reply-header {
  padding: 0 30px;
  font-size: 14px;
  font-weight: bold;
  color: #292929;
  line-height: 52px;
  background: rgba(245, 245, 245, 1);
}
.reply-content {
  padding: 30px;
}
.reply-input {
  max-width: 500px;
  padding: 10px;
  /* border: 1px solid #ccc; */
  /* margin: 15px 0; */
}
.reply-input i {
  font-size: 48px;
  color: #d8d8d8;
  line-height: 54px;
  margin-right: 20px;
}
.reply-header1 {
  font-size: 14px;
  font-weight: bold;
  color: #292929;
  line-height: 40px;
  border-bottom: 1px solid #5074ee;
}
.reply-header1 > span {
  font-weight: 400;
  font-size: 12px;
  color: #808080;
  margin-left: 10px;
}
.reply-item {
  padding: 20px 0 11px 0;
  border-bottom: 1px solid #dce1f1;
}
.reply-name {
  font-size: 14px;
  color: #5074ee;
  line-height: 20px;
  margin-bottom: 14px;
}
.reply-name .bg_img {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  margin-right: 10px;
}
.reply-padding .reply-text {
  font-size: 14px;
  color: #292929;
  line-height: 20px;
  margin-bottom: 10px;
}
.reply-padding .reply-time {
  font-size: 12px;
  color: #b3b3b3;
  line-height: 20px;
}
</style>
<style lang="less" scoped>
.interaction {
  background: #f0f2f5;
  &-item {
    background: #ffffff;
    height: 246px;
    padding: 20px;
    border: 1px solid #e6e6e6;
  }
}

.contract-upload {
  margin-top: 20px;
  max-width: 600px;
}
</style>
<template>
    <!-- 应答内容表单 -->
    <div style="max-width: 400px;margin: 0 auto;">
        <div v-if="selectType == 1">
            <SelectList @hide="selectType=2" @selectItem="selectItem"></SelectList>
        </div>
        <div v-if="selectType == 2">
            <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="110px" class="login-form">
                <el-form-item prop="itemName" label="选择商品">
                    <div class="flex">
                        <div style="width:calc(100% - 80px)">
                            <el-input disabled v-model="ruleForm.itemName" placeholder="请选择产品"></el-input>
                        </div>
                        <div style="margin-left:10px">
                            <el-button @click="showMesList()">选择</el-button>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item prop="giveDate" label="交货时间">
                    <el-date-picker style="width:calc(100% - 80px)" v-model="ruleForm.giveDate"
                        type="date" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item prop="price" label="出价">
                    <el-input type="number" style="width:calc(100% - 80px)" min="1" placeholder="请填写出价" v-model="ruleForm.price"></el-input>
                </el-form-item>
                <div style="text-align:center;padding-top:15px;">
                    <el-button type="primary" @click="submitForm()" style="width: 90px;">提交</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import { peitaoOfferAdd } from '@/service/want';
    import SelectList from './SelectList.vue';
    export default {
        components: {SelectList,},
        props: [],
        data() {
            return {
                rules: {
                    price: [
                        { required: true, message: '请填写出价', trigger: 'blur' }
                    ],
                    giveDate: [
                        { required: true, message: '请选择日期', trigger: 'blur' }
                    ],
                    itemName: [
                        { required: true, message: '请选择产品', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    requirementId:this.$route.params.id,
                    itemId:"",
                    price:"",
                    giveDate:"",
                },
                selectType:2,
                select_item:{},
            }
        },
        // watch:{
        //     form:{
        //         handler() {
        //             this.ruleForm = this.form;
        //         },
        //         deep: true
        //     }
        // },
        created() {
            this.comp = this.$store.state.loginUser.comp;
            console.log(this.ruleForm);
        },
        mounted() {},
        methods: {
            showMesList() {
                this.selectType = 1;
            },
            selectItem(item) {
                console.log(item);
                this.select_item = item;
                this.ruleForm.itemId = item.id;
                this.ruleForm.itemName = item.name;
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit:_.debounce(function(){
                let _item = _.clone(this.ruleForm);
                let text = null;
                this.$delete(_item,'itemName')
                _item.giveDate = new Date(_item.giveDate).getTime();
                console.log(_item);
                let obj = {
                    requirementId:_item.requirementId
                }
                if (this.comp && this.comp.comp_type) {
                    switch(this.comp.comp_type) {
                        case 1:
                            obj.peitao = {
                                itemId: _item.itemId,
                                price: _item.price,
                                giveDate: _item.giveDate,
                            }
                            break;
                        case 2:
                            obj.gaikuang = {
                                itemId: _item.itemId,
                                price: _item.price,
                                giveDate: _item.giveDate,
                            }
                            break;
                        case 4:
                            obj.dayang = {
                                itemId: _item.itemId,
                                price: _item.price
                            }
                            break;
                    }
                }
                peitaoOfferAdd(obj).then(rst => {
                    this.$emit("hide");
                    this.$emit("onSuccess");
                    this.$message.success("提交成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },1000),
        }
    }
</script>
<style scoped>
    .list>div {
        padding: 5px 0;
    }
    .list>>> input[type=number] {
        padding: 0 0 0 15px;
    }
    
</style>
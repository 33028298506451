<template>
  <el-row class="unaccept" type="flex" align="middle">
    <el-col>
      <el-row type="flex" justify="center">
        <el-col :span="12" class="no-reply">
          <div>
            <img
              :src="staticUrl+'/design/placeholder/meiyougengduo.png'"
              width="24"
              alt="加载中..."
              class="no-img"
            />
          </div>
          <el-button type="text" style="color: #B3B3B3;">未应答</el-button>
        </el-col>
        <el-col :span="12">
          <slot></slot>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import { STATIC_URL_PRE } from "@/config";
export default {
  data() {
    return {
      staticUrl: STATIC_URL_PRE
    };
  }
};
</script>
<style lang="less" scoped>
.unaccept {
  height: 180px;
  color: #b3b3b3;
  .no-reply {
    text-align: right;
    margin-right: 20px;
  }
  .no-img {
    margin-right: 10px;
  }
}
</style>
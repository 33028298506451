<template>
    <!-- 选择配套需求（加工需求） -->
    <div>
        <div style="margin-bottom:20px;" @click="back()" class="flex flex-justify-between">
            <el-button type="primary" plain size="mini">返回</el-button>
            <el-button v-if="comp.comp_type==1" @click="creatGoods()" type="primary" size="mini">新增自有商品</el-button>
            <el-button v-if="comp.comp_type==2" @click="creatGoods()" type="primary" size="mini">新增作品</el-button>
        </div>
        <div class="frm">
            <el-table :data="selectList">
                <el-table-column prop="id" width="60px" label="选择">
                    <template slot-scope="scope">
                        <span class="radio_button">
                            <input type="radio" @click="select(scope.row,scope.$index)" :checked="scope.$index == mesIndex" name="radio" :id="scope.$index" class="regular-radio pull-right"/>
                            <label :for="scope.$index"></label>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="商品图">
                    <template slot-scope="scope">
                        <div style="width: 120px;height: 120px;">
                            <div v-if="scope.row.photoRender" class="bg_img"
                                :style="{backgroundImage: 'url(' +imgUrl+scope.row.photoRender[0]+'!width_250px'+ ')'}"></div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="商品名称">
                    <template slot-scope="scope">
                        <a @click="goto('/factory/zysp/detail/'+scope.row.id)">{{scope.row.name}}</a>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { peitaoProducts,gaikuangProducts } from '@/service/want';
    export default {
        model: {
            prop: 'mesIdx',
            event: 'change',
        },
        props:['mesList','mesIdx'],
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                mesIndex:null,
                queryOpt:{
                    where:{
                        ids:null,
                        key:null,
                        catIds:null,
                        priceType:[0,2],
                        isMaster:true,
                    },
                    // page:{
                    //     pageNumber:1,
                    //     pageCount:16,
                    // },
                    order: [
                        ["createdAt","desc"]
                    ],
                },
                selectList:[],
                comp:null,
            }
        },
        watch: {
        },
        created() {
            this.comp = this.$store.state.loginUser.comp;
            this.refreshItems();
        },
        mounted() {},
        methods: {
            goto(path) {
                this.$router.push(path);
            },
            refreshItems() {
                console.log('refreshItems');
                if (this.comp && this.comp.comp_type) {
                    switch(this.comp.comp_type) {
                        case 1:
                            this.peitao(); break;
                        case 2:
                            this.gaikuan(); break;
                    }
                }
            },
            peitao() {
                // 这个版本改成调用张朝云产品中心接口（工厂自有商品列表）
                this.queryOpt.where.priceType = [0,2];
                peitaoProducts(this.queryOpt).then(rst => {
                    if (rst.rows && rst.rows.length>0) {
                        this.selectList = rst.rows;
                    }
                    console.log("selectList",this.selectList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            gaikuan() {
                // 这个版本改成调用张朝云产品中心接口（设计公司商品列表）
                gaikuangProducts().then(rst => {
                    if (rst.rows && rst.rows.length>0) {
                        this.selectList = rst.rows;
                    }
                    console.log("selectList",this.selectList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            select(item,index) {
                this.mesIndex = index;
                this.$emit('selectItem',item);
                this.$emit('hide');
                console.log(index);
            },
            back() {
                this.$emit('hide',);
            },
            creatGoods() {
                if (this.comp && this.comp.comp_type) {
                    switch(this.comp.comp_type) {
                        case 1:
                            this.$router.push('/factory/zysp/submit'); break;
                        case 2:
                            this.$router.push('/desgin/fchz/submit'); break;
                    }
                }
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .frm {
        max-height:400px;
        border-top:1px solid #EBEEF5;
        border-bottom:1px solid #EBEEF5;
        overflow-y:auto;
    }
    div>>> .el-table {
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
    }
    div>>> .el-table .cell {
        text-align: center;
    }
    .radio_button {
        margin: 0 auto 0;
    }
</style>